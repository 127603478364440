<template>
  <!-- 智慧工会页面 -->
  <div class="product">
    <div class="banner">
      <div class="title">
        智慧工会整体解决方案
      </div>
    </div>
    <div class="wrapper">
      <!-- 建设思路 -->
      <div class="first">
        <div class="body">
          <div class="title">建设思路</div>
          <div class="culture_card">
            <div class="culture_card_wrapper article">
              <div class="culture_content">
                <div style="font-size:20px;font-weight:500;margin-bottom:15px">
                  坚持需求导向
                </div>
                <div style="font-size: 13px;line-height:1.5">
                  智慧工会的建设要把握职工用户最关心、最直接、最现实的需求，智能数据库、智能管理平台、智能统计分析算法的最终落脚点是职工用户和工作人员的实际应用场景
                </div>
              </div>
            </div>
            <div class="culture_card_wrapper image">
              <img src="https://yunch-1302520809.cos.ap-nanjing.myqcloud.com/yc-website/images/product/first_ex1.png"
                width="100%" />
            </div>
            <div class="culture_card_wrapper article">
              <div class="culture_content">
                <div style="font-size:20px;font-weight:500;margin-bottom:15px">
                  线上线下融合
                </div>
                <div style="font-size: 13px;line-height:1.5">
                  通过智慧工会建设与网上服务的开展，实现线上线下有机融合，打造品牌，形成亮点，为职工群众提供用的上、用得起、用得好的网上服务。
                </div>
              </div>
            </div>
            <div class="culture_card_wrapper image">
              <img src="https://yunch-1302520809.cos.ap-nanjing.myqcloud.com/yc-website/images/product/first_ex2.png"
                width="100%" />
            </div>
            <div class="culture_card_wrapper image">
              <img src="https://yunch-1302520809.cos.ap-nanjing.myqcloud.com/yc-website/images/product/first_ex3.png"
                width="100%" />
            </div>
            <div class="culture_card_wrapper article">
              <div class="culture_content">
                <div style="font-size:20px;font-weight:500;margin-bottom:15px">
                  做好顶层设计
                </div>
                <div style="font-size: 13px;line-height:1.5">
                  从长远角度出发，确定“智慧工会”建设的目标、边界、内容，结合实际工作指定行业建设标准，形成工会网上工作一盘棋；优化工会业务服务流程以适应网上工作开展。
                </div>
              </div>
            </div>
            <div class="culture_card_wrapper image">
              <img src="https://yunch-1302520809.cos.ap-nanjing.myqcloud.com/yc-website/images/product/first_ex4.png"
                width="100%" />
            </div>
            <div class="culture_card_wrapper article">
              <div class="culture_content">
                <div style="font-size:20px;font-weight:500;margin-bottom:15px">
                  创新服务方式
                </div>
                <div style="font-size: 13px;line-height:1.5">
                  主动顺应信息化要求、强化互联网思维、搭建“智慧工会”平台，在职工中广泛普及平台运用知识，让广大职工实实在在地享受到“智慧工会”带来的各项便利和实惠。
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 详细功能 -->
      <div class="second">
        <div class="body">
          <div class="title">详细功能</div>
          <div class="culture_card">
            <div class="item" v-for="item in secondCardList" :key="item.id">
              <img class="icon" :src="item.coverUrl" alt="" />
              <div>{{ item.title }}</div>
            </div>
          </div>
        </div>
      </div>
      <!-- 产品优势 -->
      <div class="third">
        <div class="body">
          <div class="title">产品优势</div>
          <div class="culture_card">
            <div class="item" v-for="item in thirdCardList" :key="item.id">
              <div>
                <div style="font-size:20px;font-weight:600;margin-bottom:15px;">
                  {{ item.title }}
                </div>
                <div style="font-size: 15px;font-weight: 300;line-height:1.5;text-align: left;">
                  {{ item.summary }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 平台价值 -->
      <div class="fourth">
        <div class="left">
          <div>
            <div class="title">平台价值</div>
            <div class="content" style="line-height:1.5">
              “智慧工会”的建设是一项创新性、系统性、持续性工程，需要借助随互联网时代发展起来的最新技术，并不断创新优化，以真正贴合工会服务业务场景，最终实现“智慧工会”既定目标。
            </div>
          </div>
        </div>
        <div class="right">
          <div class="item">
            <div>
              <img src="https://yunch-1302520809.cos.ap-nanjing.myqcloud.com/yc-website/images/product/fourth_ex1.png"
                alt="" />
              <div class="title">普惠性服务</div>
              <div class="content" style="line-height:1.5">
                以职工为中心，以网络平台为载体，推进个性化普惠服务，创新工会服务形式和工作方法，入福利发放，服务驿站，智慧商城等
              </div>
            </div>
          </div>
          <div class="item">
            <div>
              <img src="https://yunch-1302520809.cos.ap-nanjing.myqcloud.com/yc-website/images/product/fourth_ex2.png"
                alt="" />
              <div class="title">便捷化服务</div>
              <div class="content" style="line-height:1.5">
                通过工会信息化建设，把工会网上工作作为联系职工、服务职工的重要平台，构建网上线下相互促进、有机融合的工会工作体系，便捷化服务职工。
              </div>
            </div>
          </div>
          <div class="item">
            <div>
              <img src="https://yunch-1302520809.cos.ap-nanjing.myqcloud.com/yc-website/images/product/fourth_ex3.png"
                alt="" />
              <div class="title">常态化服务</div>
              <div class="content" style="line-height:1.5">
                通过建设智慧工会，把帮扶援助、培训就业、法律援助、心理服务等内容作为职工服务的重要渠道；利用工会平台形成常态化工会服务。
              </div>
            </div>
          </div>
          <div class="item">
            <div>
              <img src="https://yunch-1302520809.cos.ap-nanjing.myqcloud.com/yc-website/images/product/fourth_ex4.png"
                alt="" />
              <div class="title">精细化管理与运营</div>
              <div class="content" style="line-height:1.5">
                实现会员线上线下一体化、规范化、流程化管理。实名制管理，通过工会大数据，服务职工更精确。通过分析工会运营数据，持续优化服务内容与服务流程。
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 建设原则 -->
      <div class="fifth">
        <div class="fifthbody">
          <div class="slogan">建设原则</div>
          <div class="culture_card">
            <div class="item long">
              <img src="https://yunch-1302520809.cos.ap-nanjing.myqcloud.com/yc-website/images/product/ex_img1.png"
                alt="" width="100%" />
              <div class="box">
                <div class="title" style="font-size:20px;font-weight:500;margin-bottom:15px">
                  开放性
                </div>
                <div class="content" style="font-size: 14px;font-weight: 300;">
                  平台服务广大职工群众，提高服务内容与服务覆盖面
                </div>
              </div>
            </div>
            <div class="item short">
              <img src="https://yunch-1302520809.cos.ap-nanjing.myqcloud.com/yc-website/images/product/ex_img2.png"
                alt="" width="100%" />
              <div class="box">
                <div class="title" style="font-size:20px;font-weight:500;margin-bottom:15px">
                  持续性
                </div>
                <div class="content" style="font-size: 14px;font-weight: 300;">
                  持续服务、方式持续改进、平台持续优化
                </div>
              </div>
            </div>
            <div class="item short">
              <img src="https://yunch-1302520809.cos.ap-nanjing.myqcloud.com/yc-website/images/product/ex_img3.png"
                alt="" width="100%" />
              <div class="box">
                <div class="title" style="font-size:20px;font-weight:500;margin-bottom:15px">
                  兼容性
                </div>
                <div class="content" style="font-size: 14px;font-weight: 300;">
                  采用标准统一的接口设计，可实现与其他业务系统进行对接
                </div>
              </div>
            </div>
            <div class="item long">
              <img src="https://yunch-1302520809.cos.ap-nanjing.myqcloud.com/yc-website/images/product/ex_img4.png"
                alt="" width="100%" />
              <div class="box">
                <div class="title" style="font-size:20px;font-weight:500;margin-bottom:15px">
                  安全性
                </div>
                <div class="content" style="font-size: 14px;font-weight: 300;">
                  架构安全、代码安全、系统安全、数据安全
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Foot></Foot>
  </div>
</template>
<script>
import Foot from "@/components/footer/footer.vue";
import { newsPage } from "@/api/news.js";
export default {
   metaInfo: {
      title: '智慧工会-商城', // set a title
      meta: [{                 // set meta
        name: '云窗科技、工会、智慧工会、工会平台、工会系统、职工服务平台、工会管理系统、工会管理平台、高校工会、高校工会平台、工会信息化建设、普惠服务、福利发放、提案系统、教代会平台、企业工会、集团工会',
        content: '云窗科技、工会、智慧工会、工会平台、工会系统、职工服务平台、工会管理系统、工会管理平台、高校工会、高校工会平台、工会信息化建设、普惠服务、福利发放、提案系统、教代会平台、企业工会、集团工会'
      }],
      link: [{                 // set link
        rel: 'asstes',
        href: 'http://yunchuangtech.com/'
      }]
   },
  components: { Foot },
  data() {
    return {
      secondCardList: [], //详细功能数据
      thirdCardList: [] //产品优势数据
    };
  },
  created() {
    this.getData();
  },
  methods: {
    async getData() {
      let params = {
        pageSize: 99,
        pageNum: 1,
        categoryId: 25
      };
      let { data: secondRes } = await newsPage(params); //详细功能
      params.categoryId = 26;
      let { data: thirdRes } = await newsPage(params); //产品优势
      this.secondCardList = secondRes.data.records;
      this.thirdCardList = thirdRes.data.records;
    }
  },
  computed: {}
};
</script>

<style lang="scss" scoped>
// .product {
//   .banner {
//     height: 380px;
//     width: 100%;
//     background-image: url(~https://yunch-1302520809.cos.ap-nanjing.myqcloud.com/yc-website/images/product/banner.png);
//     background-position: 50% 10%;
//     background-size: 100% 552px;
//     position: relative;
//     background-repeat: no-repeat;

//     .title {
//       font-size: 28px;
//       font-weight: 500;
//       color: #fff;
//       position: absolute;
//       left: 8%;
//       top: 50%;
//       border-bottom: 1px solid;
//       padding-bottom: 10px;
//     }
//   }

//   .wrapper {
//     background-color: #fff;

//     .first {
//       height: 600px;
//       background-color: #ffffff;
//       display: flex;
//       justify-content: center;

//       .body {
//         width: 100%;
//         display: flex;
//         justify-content: center;
//         flex-direction: column;

//         .title {
//           flex: 1;
//           display: flex;
//           justify-content: center;
//           align-items: flex-end;
//           font-size: 20px;
//           font-weight: 600;
//         }

//         .culture_card {
//           background-image: url(~https://yunch-1302520809.cos.ap-nanjing.myqcloud.com/yc-website/images/product/bg1.png);
//           width: 100%;
//           background-position: center;
//           background-size: 100%;
//           background-repeat: no-repeat;
//           flex: 3;
//           display: flex;
//           flex-wrap: wrap;
//           justify-content: space-between;
//           align-content: flex-start;
//           margin-top: 40px;
//           padding: 0 8%;
//           padding-bottom: 20px;
//           box-sizing: border-box;
//           height: 450px;

//           .culture_card_wrapper {
//             width: 25%;
//             height: 45%;
//             display: flex;
//             border: 1px solid #dddddd;
//             padding-left: 20px;
//             background-color: #fff;
//             box-sizing: border-box;

//             &.article {
//               padding: 15px;
//             }

//             &.image {
//               padding: 0;
//             }
//           }

//           .culture_icon {
//             display: flex;
//             align-items: center;
//           }

//           .culture_content {
//             flex: 1;
//             display: flex;
//             flex-direction: column;
//             justify-content: center;
//             padding-left: 20px;
//           }
//         }
//       }
//     }

//     //详细功能
//     .second {
//       background-color: #ffffff;
//       display: flex;
//       justify-content: center;
//       padding-bottom: 30px;
//       background-image: url(~https://yunch-1302520809.cos.ap-nanjing.myqcloud.com/yc-website/images/product/bg2.png);
//       width: 100%;
//       background-position: center;
//       background-size: 100%;
//       background-repeat: no-repeat;

//       .body {
//         width: 100%;
//         display: flex;
//         justify-content: center;
//         flex-direction: column;
//         padding-top: 50px;

//         .title {
//           flex: 1;
//           display: flex;
//           justify-content: center;
//           align-items: flex-end;
//           font-size: 20px;
//           font-weight: 600;
//         }

//         .culture_card {
//           flex: 3;
//           display: grid;
//           grid-template-columns: 100px 100px 100px 100px 100px 100px;
//           grid-row-gap: 10px;
//           flex-wrap: wrap;
//           justify-content: space-between;
//           margin-top: 40px;
//           padding: 0 14%;
//           box-sizing: border-box;
//           text-align: center;

//           .item {
//             height: 100px;
//             height: 100px;

//             .icon {
//               display: inline-block;
//               padding-bottom: 7px;
//             }
//           }
//         }
//       }
//     }

//     // 产品优势
//     .third {
//       background-color: #ffffff;
//       display: flex;
//       justify-content: center;
//       padding-bottom: 30px;
//       background-image: url(~https://yunch-1302520809.cos.ap-nanjing.myqcloud.com/yc-website/images/product/bg3.png);
//       width: 100%;
//       background-position: center;
//       background-size: 100% 500px;
//       background-repeat: no-repeat;

//       .body {
//         width: 100%;
//         display: flex;
//         justify-content: center;
//         flex-direction: column;
//         padding-top: 50px;

//         .title {
//           flex: 1;
//           display: flex;
//           justify-content: center;
//           align-items: flex-end;
//           font-size: 20px;
//           font-weight: 600;
//         }

//         .culture_card {
//           flex: 3;
//           display: grid;
//           grid-template-columns: 34% 34% 34%;
//           grid-row-gap: 10px;
//           flex-wrap: wrap;
//           justify-content: space-between;
//           margin-top: 40px;
//           padding: 0 10%;
//           box-sizing: border-box;
//           padding-bottom: 45px;

//           .item {
//             color: #fff;
//             border-radius: 5px;
//             width: 90%;
//             height: 200px;
//             background-color: rgba(246, 90, 74, 0.6);
//             text-align: center;
//             display: flex;
//             justify-content: flex;
//             align-items: center;
//             padding: 25px;
//             box-sizing: border-box;
//           }
//         }
//       }
//     }

//     //平台价值
//     .fourth {
//       height: 500px;
//       display: flex;
//       justify-content: space-between;

//       .left {
//         width: 45%;
//         height: 500px;
//         background-image: url(~https://yunch-1302520809.cos.ap-nanjing.myqcloud.com/yc-website/images/product/bg4.png);
//         // background-position: 50% 10%;
//         background-size: 100% 500px;
//         color: #fff;
//         display: flex;
//         align-items: center;
//         padding: 40px;
//         box-sizing: border-box;

//         .title {
//           margin-top: 10%;
//           margin-left: 19%;
//           font-size: 22px;
//           font-weight: 700;
//         }

//         .content {
//           margin-left: 19%;
//           margin-top: 25px;
//         }
//       }

//       .right {
//         width: 55%;
//         height: 500px;
//         display: flex;
//         flex-wrap: wrap;

//         .item {
//           display: flex;
//           align-items: center;
//           background-color: rgb(248, 248, 248);
//           width: 50%;
//           height: 250px;
//           padding: 30px;
//           box-sizing: border-box;

//           &:nth-child(1) {
//             border: 1px solid rgba(0, 0, 0, 0.3);
//             border-top: none;
//             border-left: none;
//           }

//           &:nth-child(3) {
//             border-right: 1px solid rgba(0, 0, 0, 0.3);
//           }

//           &:nth-child(2) {
//             border-bottom: 1px solid rgba(0, 0, 0, 0.3);
//           }

//           .title {
//             font-size: 18px;
//             font-weight: 700;
//             padding: 20px 0;
//           }

//           .content {
//             font-size: 14px;
//           }
//         }
//       }
//     }

//     // 建设原则
//     .fifth {
//       background-color: #ffffff;
//       display: flex;
//       justify-content: center;
//       padding-bottom: 30px;
//       background-image: url(~https://yunch-1302520809.cos.ap-nanjing.myqcloud.com/yc-website/images/product/bg5.png);
//       width: 100%;
//       background-position: center;
//       background-size: 100% 500px;
//       background-repeat: no-repeat;

//       .body {
//         width: 100%;
//         display: flex;
//         justify-content: center;
//         flex-direction: column;
//         padding-top: 50px;

//         .slogan {
//           flex: 1;
//           display: flex;
//           justify-content: center;
//           align-items: flex-end;
//           font-size: 20px;
//           font-weight: 600;
//         }

//         .culture_card {
//           flex: 3;
//           display: flex;
//           flex-wrap: wrap;
//           justify-content: space-between;
//           margin-top: 40px;
//           padding: 0 10%;
//           box-sizing: border-box;

//           .item {
//             background-position: center;
//             position: relative;
//             margin-bottom: 15px;

//             &.long {
//               width: 60%;
//             }

//             &.short {
//               width: 38.5%;
//             }

//             .box {
//               position: absolute;
//               bottom: 50px;
//               left: 10%;
//               color: #fff;

//               img {
//                 height: 200px;
//               }
//             }
//           }
//         }
//       }
//     }
//   }
// }
</style>
